import React from "react";
import Renuka from "../Assets/img/renuka.svg";
import Button from "./Button";

export default function Projects() {
  return (
    <>
      <div className="projects section">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-6">
              <div className="cont mb-2 primary-text">Projects</div>
              <h2>Our Showcased Masterpieces</h2>
              <p className="cont primary-text">
                Explore our portfolio of successfully completed projects that
                showcase our expertise and commitment to quality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="project_detail">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 pro_renuka text-center">
              <img src={Renuka} alt="" className=" pb-2" />
              <h3>Renuka Enclave</h3>
              <p className="cont primary-text">
                Discover the epitome of comfort and convenience at Renuka
                Enclave. Nestled in the heart of Bengaluru, this exquisite
                residential project offers thoughtfully designed 2BHK and 3BHK
                apartments. Experience the perfect blend of modern living and
                serene surroundings.
              </p>
              <Button
                url="/projects/renukaenclave"
                title="View Property"
                className="pt-2"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
