import React from 'react'
import ImageCarousel from "../Components/ImageCarousel";
import Carousel1 from "../Assets/img/carousel/1.png";
import Carousel2 from "../Assets/img/carousel/2.jpg";
import Carousel3 from "../Assets/img/carousel/3.jpg";
import Carousel4 from "../Assets/img/carousel/4.jpg";
import Carousel5 from "../Assets/img/carousel/5.jpg";
import Carousel6 from "../Assets/img/carousel/6.jpg";
import Carousel7 from "../Assets/img/carousel/7.jpg";
import Carousel8 from "../Assets/img/carousel/8.jpg";
import Carousel9 from "../Assets/img/carousel/9.jpg";
import Carousel10 from "../Assets/img/carousel/10.jpg";
import Carousel11 from "../Assets/img/carousel/11.jpg";


export default function AboutSamrat() {
    const images = [
      Carousel1,
      Carousel2,
      Carousel3,
      Carousel4,
      Carousel5,
      Carousel6,
      Carousel7,
      Carousel8,
      Carousel9,
      Carousel10,
      Carousel11,
    ];
  return (
    <>
      <div className="about">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4">
              <div className="cont mb-2 primary-text">About</div>
              <h2>SAMRAT VENTURES</h2>
            </div>
            <div className="col-md-7">
              <p className="font-24 font-bold secondary-text">
                Legacy of Excellence in Construction
              </p>
              <div className="secondary-text cont">
                Rooted in the heritage of Ashoka, Samrat Ventures is a
                pioneering Bengaluru-based construction and property development
                company. Our commitment to quality, innovation, and
                sustainability sets us apart. With a team of experienced
                engineers and architects, we create exceptional spaces that
                blend traditional charm with modern functionality.
              </div>
            </div>
          </div>
          <div className="pt-5">
            <ImageCarousel images={images} autoplay={true} interval={2000} />
          </div>
        </div>
      </div>
    </>
  );
}
