import React from "react";
import { Link } from "react-router-dom";

export default function Button(props) {
  return (
    <>
      <div className={props.className}>
        <Link to={props.url} className="primbtn">
          {props.title}
        </Link>
      </div>
    </>
  );
}
