import React, { useState } from "react";

import { IoMenu } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import Logo from "../Assets/img/logo.svg";

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const Navbar = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About",
      link: "/about",
    },
    {
      text: "Services",
      link: "/services",
    },
    {
      text: "Projects",
      link: "/projects",
    },
    {
      text: "Blogs",
      link: "/blog",
    },
    {
      text: "Contact",
      link: "/contact",
    },
  ];

  return (
    <div className="header">
      <nav className="container">
        <div className="nav-logo-container">
          <NavLink to="/">
            <img src={Logo} alt="" />
          </NavLink>
        </div>
        <div className="d-flex align-items-center">
          <div className="navbar-links-container">
            <NavLink to="/" activeclassname="active">
              Home
            </NavLink>
            <NavLink to="/about" activeclassname="active">
              About
            </NavLink>
            <NavLink to="/services" activeclassname="active">
              Services
            </NavLink>
            <NavLink to="/projects" activeclassname="active">
              Projects
            </NavLink>
            <NavLink to="/blog" activeclassname="active">
              Blogs
            </NavLink>
            <NavLink to="/contact" activeclassname="active">
              Contact
            </NavLink>
          </div>
          <button className="themebtn" onClick={props.handleMode}>
            {props.btnIcon}
          </button>
        </div>
        <div className="navbar-menu-container">
          <IoMenu onClick={() => setOpenMenu(true)} className="menu-bar-icon" />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
            className={`mob-nav-area `}
          >
            <List className="mob-nav">
              {menuOptions.map((item) => (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton>
                    <ListItemText className="men-items">
                      <NavLink to={item.link}>{item.text}</NavLink>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
