import React from "react";
import Banner from "../Components/Banner";
import AboutSamrat from "../Components/AboutSamrat";
import Services from "../Components/Services";
import Projects from "../Components/Projects";
import CTA from "../Components/CTA";

export default function Home() {
  return (
    <>
      <Banner />
      <AboutSamrat />
      <Services />
      <Projects />
      <CTA />
    </>
  );
}
