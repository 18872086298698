import React from "react";
import Services1 from "../Assets/img/services_1.svg";
import { Link } from "react-router-dom";
import s1 from "../Assets/img/s1.svg";
import s2 from "../Assets/img/s2.svg";
import s3 from "../Assets/img/s3.svg";
import s4 from "../Assets/img/s4.svg";
import s5 from "../Assets/img/s5.svg";
import s6 from "../Assets/img/s6.svg";

export default function Services() {
  const services = [
    {
      url: "/",
      img: s1,
      title: "Residential Buildings",
    },
    {
      url: "/",
      img: s2,
      title: "Commercial Buildings",
    },
    {
      url: "/",
      img: s3,
      title: "Industrial Buildings",
    },
    {
      url: "/",
      img: s4,
      title: "Educational Buildings",
    },
    {
      url: "/",
      img: s5,
      title: "Hospital Buildings",
    },
    {
      url: "/",
      img: s6,
      title: "Hospitality Buildings",
    },
  ];
  return (
    <>
      <div className="services section">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="cont mb-2 primary-text">Our Services</div>
              <h2>Comprehensive Construction Solutions for Your Vision</h2>
              <p className="cont">
                Samrat Ventures offers a wide range of construction services
                tailored to your needs. From luxurious residential apartments to
                state-of-the-art commercial spaces, we deliver excellence in
                every project.
              </p>
              <img src={Services1} alt="" className="img-fluid" />
            </div>
            <div className="col-md-6 services-img text-right">
              <div className="item">
                {services.map((item, index) => (
                  <Link to={item.url}>
                    <div className="items" key={index}>
                      <img src={item.img} alt="" className="img-fluid" />
                      <div className="service_name">{item.title}</div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
