import React from "react";
import CTAimg from "../Assets/img/cta_img.svg";
import Button from "./Button";

export default function CTA() {
  return (
    <>
      <div className="cta_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <img src={CTAimg} alt="" className="img-fluid" />
            </div>
            <div className="col-md-5">
              <h1>Limited Units Available</h1>
              <Button
                url="https://wa.me/7204909377/"
                title="Book Now!"
                className="pt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
