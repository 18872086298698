import "./App.css";
import "./Assets/styles/custom.scss";
import "./Assets/styles/responsive.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Footer from "./Components/Footer";
import AboutSamrat from "./Components/AboutSamrat";
import Services from "./Components/Services";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";
import RenukaEnclave from "./Components/Projects/RenukaEnclave";
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutSamrat />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/renukaenclave" element={<RenukaEnclave />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
