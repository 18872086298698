import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/footer_logo.svg";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export default function Footer() {
  const footer_menu = [
    {
      url: "/",
      title: "Home",
    },
    {
      url: "/about",
      title: "About",
    },
    {
      url: "/services",
      title: "Services",
    },
    {
      url: "/projects",
      title: "Projects",
    },
    {
      url: "/blog",
      title: "Blogs",
    },
    {
      url: "/contact",
      title: "Contact",
    },
  ];
  const socials = [
    {
      url: "/",
      icon: <FaFacebookF />,
    },
    {
      url: "/",
      icon: <FaInstagram />,
    },
    {
      url: "/",
      icon: <FaXTwitter />,
    },
    {
      url: "/",
      icon: <FaLinkedinIn />,
    },
  ];
  return (
    <>
      <div className="footer ptb-5">
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-12 mobcenter mobpb">
              <div>
                <div>
                  <Link to="/">
                    {" "}
                    <img src={Logo} alt="" className="img-fluid" />
                  </Link>
                </div>
                <div className="footer_menu pt-5">
                  {footer_menu.map((item, index) => (
                    <>
                      <span key={index}>
                        <Link to={item.url}>{item.title}</Link>
                      </span>
                    </>
                  ))}
                </div>
                <div className="socialicons pt-5">
                  {socials.map((item, index) => (
                    <>
                      <span key={index}>
                        <Link to={item.url}>
                          <i>{item.icon}</i>
                        </Link>
                      </span>
                    </>
                  ))}
                </div>
                <div className="pt-5">
                  <small className="secondary-text">
                    © 2024{" "}
                    <Link to="/" className="text-light">
                      Samrat Ventures.{" "}
                    </Link>{" "}
                    All Rights Reserved.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
