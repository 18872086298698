import React from "react";
import Button from "../Button";

export default function AboutSamrat() {
  return (
    <>
      <div className="about project">
        <div className="container">
          <div className="row justify-content-between ">
            <div className="col-md-4">
              <div className="fixed">
                <div className="cont mb-2 primary-text">Projects</div>
                <h2>Renuka Enclave</h2>
              </div>

              <p></p>
            </div>
            <div className="col-md-7">
              <p className="font-24 font-bold secondary-text">
                Discover Your Dream Home at Renuka Enclave by Samrat Ventures
              </p>
              <div className="secondary-text cont pb-3">
                If you're searching for a perfect blend of modern living and
                serene surroundings in Bengaluru, look no further than Renuka
                Enclave by Samrat Ventures. This new residential project,
                nestled in the heart of Kengeri Satellite Town, offers
                thoughtfully designed 2BHK and 3BHK apartments tailored to meet
                the needs of today's urban families.
              </div>
              <p className="font-24 font-bold secondary-text">
                Samrat Ventures: Building the Future with Quality and Innovation
              </p>
              <div className="secondary-text cont pb-3">
                Samrat Ventures is a leading construction and property
                development company based in Bengaluru, known for its commitment
                to quality, innovation, and sustainability. With a team of
                highly experienced engineers and architects, Samrat Ventures has
                established a reputation for creating exceptional spaces that
                seamlessly blend traditional charm with modern functionality.
                Renuka Enclave is the latest testament to this philosophy,
                offering residents a unique living experience that goes beyond
                the ordinary.
              </div>
              <p className="font-24 font-bold secondary-text">
                Renuka Enclave: The Perfect Address for Modern Living
              </p>
              <div className="secondary-text cont pb-3">
                Samrat Ventures is a leading construction and property
                development company based in Bengaluru, known for its commitment
                to quality, innovation, and sustainability. With a team of
                highly experienced engineers and architects, Samrat Ventures has
                established a reputation for creating exceptional spaces that
                seamlessly blend traditional charm with modern functionality.
                Renuka Enclave is the latest testament to this philosophy,
                offering residents a unique living experience that goes beyond
                the ordinary.
              </div>
              <p className="font-24 font-bold secondary-text">
                Why Choose Renuka Enclave?
              </p>
              <div className="secondary-text cont pb-3">
                Choosing Renuka Enclave means investing in a lifestyle where
                everything you need is right at your doorstep. From educational
                institutions to healthcare facilities, from convenient
                transportation options to major business centers, every aspect
                of your life is catered to.
                <br />
                Samrat Ventures' commitment to quality construction and
                sustainable living ensures that your home is not just a place to
                live, but a sanctuary where you can relax, rejuvenate, and
                create lasting memories with your loved ones.
                <br />
                Renuka Enclave by Samrat Ventures is not just another
                residential project—it’s a community that offers modern living
                in a serene environment. With its strategic location,
                exceptional design, and affordability, it’s the perfect place to
                call home. Whether you're looking for a 2BHK or 3BHK apartment,
                Renuka Enclave offers the ideal living space for your family.
                <br />
                Don’t miss out on the opportunity to own your dream home in
                Bengaluru.{" "}
                <b>
                  Visit Renuka Enclave today and discover what modern living
                  truly means.
                </b>
                <Button
                  url="https://wa.me/7204909377/"
                  title="Book Now!"
                  className="pt-3 mt-3 lightbtn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
