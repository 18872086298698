import React from "react";
import Button from "./Button";

export default function Banner() {
  return (
    <>
      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>SAMRAT VENTURES</h1>
              <h2>Building Heritage with Modern Luxury</h2>
              <p className="font-24 primary-text">
                Where Heritage Meets Modernity
              </p>
              <Button
                url="/projects"
                title="Explore Our Luxury Apartments"
                className="pt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
