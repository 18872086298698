import React, { useState, useEffect } from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

const ImageCarousel = ({ images, autoplay = true, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let autoplayInterval;
    if (autoplay) {
      autoplayInterval = setInterval(() => {
        handleNext();
      }, interval);
    }
    return () => {
      if (autoplayInterval) {
        clearInterval(autoplayInterval);
      }
    };
  }, [currentIndex, autoplay, interval]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="carousel">
      <button className="carousel-arrow prev-arrow" onClick={handlePrev}>
       <SlArrowLeft />
      </button>
      <div className="carousel-image">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="img-fluid" />
      </div>
      <button className="carousel-arrow next-arrow" onClick={handleNext}>
        <SlArrowRight />
      </button>
    </div>
  );
};

export default ImageCarousel;
